.grid {
  &:hover {
    @media screen and (min-width: 768px) {
      .card {
        &_left {
          .heading {
            transform: translateX(calc(-110% - 2.4rem));
          }
        }
        &_right {
          .heading {
            transform: translateX(calc(110% + 2.4rem));
          }
        }
      }
    }
  }
}

.cardContainer {
  flex: 1;
  transition: 1s var(--ease-out);
  will-change: width;

  &:hover {
    @media screen and (min-width: 768px) {
      flex: 2.4;
    }
  }
}

.card {
  flex: 1;
  will-change: opacity;
  transition: opacity 0.5s ease-out;

  @media screen and (max-width: 767px) {
    &_left,
    &_right {
      // Overlay to pop text on bright images
      ::after {
        content: '';
        opacity: 0.1;
        background-color: black;
        z-index: 0;
        height: 100%;
        left: 0;
        right: 0;
        position: absolute;
      }
    }
  }

  @media screen and (min-width: 768px) {
    &_left,
    &_right {
      &:hover {
        .description {
          transform: translateX(0);
        }
      }

      .description {
        width: 30rem;
        transition: transform 1s var(--ease-out);
      }

      .heading {
        transition: transform 1s var(--ease-out);
      }

      .headingInit {
        transform: translateY(150%);
      }
    }

    &_left {
      .description {
        transform: translateX(calc(-100% - 2.4rem));
      }

      &:hover {
        .heading {
          transform: translateX(0) !important;
        }
      }
    }

    &_right {
      .description {
        transform: translateX(calc(100% + 2.4rem));
      }
      &:hover {
        .heading {
          transform: translateX(0) !important;
        }
      }
    }
  }
}
