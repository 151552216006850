html,
body {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: var(--primary);
  text-align: left;
  background-color: var(--secondary);

  // font-size: 6px;
  // root-font-size is 10px but is clamped
  // Read more about clamping & clamping function generator below:
  // https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
  // clamp( minSize, preferred, maxSize)

  // mobile viewport between 320px & 767px  - NO Clamping
  @media screen and (max-width: 767px) {
    //font-size: clamp(8.5px, 7.426px + 0.3356vw, 10px);
    font-size: 10px;
  }

  // tablet viewport between 768 & 1023 - NO Clamping
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: 5px;
  }

  // clamps desktop viewport between 1024 & 1540
  @media screen and (min-width: 1024px) {
    font-size: clamp(6.5px, -03.43px + 0.8721vw, 10px);
  }
}

.container {
  padding: 1.6rem;

  @media screen and (min-width: 768px) and (max-width: 1539px) {
    max-width: 96%;
    padding: 0;
  }
  @media screen and (min-width: 1540px) {
    max-width: 1540px;
    padding: 0;
  }
}

h1,
h1 *,
h2,
h2 *,
h3,
h3 *,
h4,
h4 *,
h5,
h5 *,
h6,
h6 * {
  font-family: 'Bebas Neue Regular', sans-serif;
}

* {
  font-family: 'Roboto Medium', sans-serif;
}

.font-light {
  font-family: 'Roboto Light', sans-serif;
}
