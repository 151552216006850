.LinkWrapper {
  &:hover {
    .Dash {
      width: 6.4rem;
    }
  }

  .Dash {
    width: 3.2rem;
    transition: width 0.5s;
    will-change: width;
  }
}

.quote {
  position: relative;
  z-index: 1;
  padding: 0 15px;
  font-size: 1.8rem;
  line-height: 2.5rem;
  color: var(--white);
  font-weight: normal;
  text-transform: uppercase;
  
  @media screen and (max-width: 768px) {
    padding: 0;
    font-size: 1.1rem;
    line-height: 1.3;
  }

  &:before {
    content: '"';
    display: block;
    transform: translate(0, 6px);
    font-size: 22px;
    color: var(--primary);
  }

  &:after {
    content: '"';
    display: block;
    transform: translate(0, 4px);
    font-size: 22px;
    color: var(--primary);
  }

  .lineClamp {
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;    
    display: -webkit-box;
    -webkit-line-clamp: 5;

    @media screen and (max-width: 768px) {
      -webkit-line-clamp: 3;
    }
  }
}

.name {
  position: relative;
  z-index: 1;
  color: var(--white);
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-top: 5px;

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
}
