.paginationButton {
  height: 40px;
  width: 40px;

  @media screen and (min-width: 768px) {
    height: 30px;
    width: 30px;
  }

  @media screen and (min-width: 1024px) {
    height: 40px;
    width: 40px;
  }
}
