/* Heading Font */
@font-face {
  font-family: 'Bebas Neue Regular';
  src: url('/fonts/bebas-neue-regular.woff2');
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
}

/* Texts & Paragraphs */
@font-face {
  font-family: 'Roboto Medium';
  src: url('/fonts/roboto-medium.woff2');
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: 'Roboto Light';
  src: url('/fonts/roboto-light.woff2');
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
}
