.menuTitle {
  color: red;
}

.socialIconsLink {
    position: relative;

    &:before {
        background-color: inherit;
        border: 1px solid var(--secondary);
        border-radius: 50%;
        content: '';
        display: block;
        height: 100%;
        opacity: 1;
        position: absolute;
        transform: scale(1.0);
        transition: transform 0.15s ease-out;
        width: 100%;
        z-index: 0;
    }

    @media (-ms-high-contrast: active), (forced-colors: active) {
        svg path {
            fill: buttonText;
        }
    }

    &:hover {
        &:before {
            border: 1px solid var(--secondary);
            opacity: 1;
            transform: scale(1.2);
        }
    }

    svg {
        fill: var(--secondary);
        z-index: 1;
    }
}
