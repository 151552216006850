.active {
  @apply md:border-b md:border-white;

  @media screen and (max-width: 768px) {
    &::after {
      @apply absolute w-full h-1 left-0 bg-secondary;
      content: '';
      transform: none;
    }
  }
}
