.arrow_button {
  position: relative;
  border: none;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid var(--primary);
    z-index: 0;
    transition: transform 0.15s var(--ease-out), background-color 0.15s var(--ease-out);
  }

  svg path {
    fill: white;
    transition: color 0.15s var(--ease-out);

    @media (-ms-high-contrast: active), (forced-colors: active) {
      fill: buttonText;
    }
  }

  @media (hover: hover) {
    &:hover {
      &:before {
        transform: scale(1.12);
        background-color: var(--secondary);
      }

      svg path {
        fill: var(--primary);

        @media (-ms-high-contrast: active), (forced-colors: active) {
          fill: buttonText;
        }
      }
    }
  }
}
