.copy_button {
  button {
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      z-index: 0;
      border: 1px solid var(--primary);
      transition: transform 0.15s var(--ease-out);
      will-change: transform;
    }

    @media (-ms-high-contrast: active), (forced-colors: active) {
      svg path {
        fill: buttonText;
      }
    }
  }

  &:hover {
    button {
      &:before {
        transform: scale(1.2);
      }
    }
  }
}
