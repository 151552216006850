.testimonial {
    margin-top: 8rem !important;
    margin-bottom: -5rem !important;

    @media screen and (min-width: 768px) {
        margin-top: 25rem !important;
        margin-bottom: -25rem !important;

        h2 {
            font-size: 10.6rem !important;

            div {
                padding-left: 0 !important;
            }
        }
    }



    &.isSmallTestimonial {
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        @media screen and (min-width: 768px) {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
    }
}