// Default - Mobile View
@media (max-width: theme('screens.md')) {
  .customGrid {
    @apply grid grid-cols-8 md:grid-cols-9;
    @apply bg-green-500;

    > div {
      @apply border-b border-primary;

      &:last-child {
        @apply border-b-0;
      }
    }
  }
}

// Desktop View 3x3 Tic-tac-toe borders
@media (min-width: theme('screens.md')) {
  .threeItems {
    @apply grid grid-cols-8 md:grid-cols-9;
    > div {
      @apply border-primary;

      &:nth-child(2) {
        @apply border-l border-r;
      }
    }
  }

  .fourItems {
    @apply grid grid-cols-8 md:grid-cols-9;
    > div {
      @apply border-primary;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        @apply border-b;
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(4) {
        @apply border-r;
      }
    }
  }

  .fiveOrSixItems {
    @apply grid grid-cols-8 md:grid-cols-9;
    > div {
      @apply border-primary;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        @apply border-b;
      }

      &:nth-child(2),
      &:nth-child(5) {
        @apply border-r border-l;
      }
    }
  }

  .sevenOrMoreItems {
    @apply grid grid-cols-8 md:grid-cols-9;
    > div {
      @apply border-primary;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(5) {
        @apply border-r border-b;
      }

      &:nth-child(3),
      &:nth-child(6) {
        @apply border-b;
      }

      &:nth-child(7),
      &:nth-child(8) {
        @apply border-r;
      }
    }
  }
}
