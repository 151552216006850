// @use '_styles/forwards' as *;


// Colors

$color-black: #333333;
$color-black-text: #333333;
$color-grey-5: #333333;
$color-white: #ffffff;


// Easings

$ease-linear: cubic-bezier(0, 0, 1, 1);
$ease-smoth-in-out: cubic-bezier(0.5, 0, 0.5, 1);
$ease-smoth-in-out2: cubic-bezier(0.36, 0, 0, 1);
$ease-expo-in: cubic-bezier(0, 0.1, 0.5, 1);
$ease-expo-in2: cubic-bezier(0.02, 0.98, 0.72, 1);
$ease-expo-out: cubic-bezier(0.5, 0, 0.99, 0.9);
$ease-expo-in-out: cubic-bezier(0, 0.74, 1, 0.25);
$ease-expo-in-out2: cubic-bezier(0.76, 0, 0.25, 1);
$ease-ease-out: cubic-bezier(0, 0, 0.2, 1);


// Spacing
$page-padding-x: 2rem;
$page-padding-x-large: 3rem;

$default-grid-gap-y: 2rem;
$default-grid-gap-y-large: 4rem;

$section-padding-y: 8rem;
$section-padding-y-large: 12rem;

$project-builder-padding-x: 12rem;
$project-builder-padding-x-large: 20rem;

// Breakpoints

$bp-xs: 415px;
$bp-xs-max: calc(#{$bp-xs} - 1px);

$bp-s: 650px;
$bp-s-max: calc(#{$bp-s} - 1px);

$bp-ms: 768px;
$bp-ms-max: calc(#{$bp-ms} - 1px);

$bp-m: 1024px;
$bp-m-max: calc(#{$bp-m} - 1px);

$bp-ml: 1440px;
$bp-ml-max: calc(#{$bp-ml} - 1px);

$bp-ls: 1680px;
$bp-ls-max: calc(#{$bp-ls} - 1px);

$grid-gutter-width: $default-grid-gap-y;
$grid-gutter-width-large: $default-grid-gap-y-large;
$grid-column-count: 4;
$grid-column-count-large: 6;

$breakpoints: (
  xs: $bp-xs,
  s: $bp-s,
  ms: $bp-ms,
  m: $bp-m,
  ml: $bp-ml,
  ls: $bp-ls,
) !default;

$breakpoints-max: (
  xs: $bp-xs-max,
  s: $bp-s-max,
  ms: $bp-ms-max,
  m: $bp-m-max,
  ml: $bp-ml-max,
  ls: $bp-ls-max,
) !default;

@mixin bp($value) {
  @media only screen and (min-width: $value) {
    @content;
  }
}

@mixin bp-max($value) {
  @media only screen and (max-width: $value) {
    @content;
  }
}

@mixin bp-desktop {
  @include bp(map-get($breakpoints, 'ms')) {
    @content;
  }
}

@mixin bp-desktop-max {
  @include bp-max(map-get($breakpoints-max, 'ms')) {
    @content;
  }
}

@mixin grid-column-span($column-count) {
  grid-column: span $column-count / span $column-count;
}

@mixin grid-column-start($column-count-start) {
  grid-column-start: $column-count-start;
}

@mixin grid {
  padding: 0 $page-padding-x;
  display: grid;
  grid-template-columns: repeat(#{$grid-column-count}, 1fr);
  grid-gap: $grid-gutter-width;

  @include bp-desktop {
    padding: 0 $page-padding-x-large;
    grid-template-columns: repeat(#{$grid-column-count-large}, 1fr);
    grid-gap: $grid-gutter-width-large;
  }

  > * {
    @include grid-column-span($grid-column-count);

    @include bp-desktop {
      @include grid-column-span($grid-column-count-large);
    }
  }
}

@keyframes tilt {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.hero {
  overflow: hidden;
  padding: 12rem 0 4.5rem;
  font-size: 1.6rem;
  color: $color-white;

  @include bp-desktop {
    padding: 14rem 0 11rem;
  }
}

.gridWrapper {
  @include grid;
  touch-action: 'none';

  @include bp-desktop {
    height: 100%;
  }
}

.gridWrapperOverflow {
  overflow: hidden;
}

.isProjectDetailPage {
  max-width: none !important;
}

.itemsWrapper {
  padding-top: 43.75%; // aspect-ratio: 16 / 7
  position: relative;
  width: 100%;

  @include bp-desktop-max {
    padding-top: 140%;
  }
}

.itemWrapper {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: transform;
}

.imageLink {
  height: 100%;
  touch-action: pan-y;
  width: 100%;

  &.desktop {
    display: none;
    @include bp-desktop {
      display: block;
    }
  }
  
  &.mobile {
    display: block;
    @include bp-desktop {
      display: none;
    }
  }
}

.image {
  background-color: $color-grey-5;
  height: 100%;
  pointer-events: none;
  position: relative;
  user-select: none;
  width: 100%;
}

.cardContainer {
  background-color: $color-white;
  bottom: 1.5rem;
  left: 1.5rem;
  padding: 2rem;
  pointer-events: none;
  position: absolute;
  right: 1.5rem;
  color: $color-black-text;

  @include bp-desktop {
    left: 2rem;
    bottom: 2rem;
    padding: 2rem;
    right: auto;
    width: 49rem;
  }
}

.cardTitle {
  // @include font-headline-4;
  margin-bottom: 2rem;

  @include bp-desktop {
    margin-bottom: 3rem;
    width: 90%;
  }
}

.cardDate {
  font-weight: 900;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
}

.cardText {
  line-height: 1.142;
  margin-bottom: 0rem;
  text-transform: uppercase;

  @include bp-desktop {
    width: 90%;
  }
}

.menuSlider {
  @include grid;
  font-weight: 900;
  justify-items: center;
  margin-top: 2rem;
  min-height: 6rem;

  @include bp-desktop {
    justify-items: unset;
  }
}

.menuSliderPrev {
  @include grid-column-span(1);
  @include grid-column-start(1);

  grid-row: 2/2;

  @include bp-desktop {
    grid-row: 1/1;
    justify-items: unset;
  }

  .arrowButton {
    display: flex;
    text-align: left;

    .arrowIcon {
      margin-right: 0.5rem;

      .arrowIconWrapper {
        transform: translateX(0rem);
      }
    }
    &:hover {
      .arrowIcon .arrowIconWrapper {
        transform: translateX(-2.5rem);
      }
    }
  }
}

.menuSliderCounter {
  @include grid-column-span(2);
  @include grid-column-start(2);

  grid-row: 2/2;
  height: min-content;
  margin-left: 2rem;
  position: relative;

  @include bp-desktop {
    @include grid-column-span(1);
    @include grid-column-start(2);
    grid-row: 1/1;
    margin-left: 0;
  }

  &::before {
    content: '●';
    font-size: 2.6rem;
    left: -2.2rem;
    margin-top: -0.1rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.menuSliderProject {
  @include grid-column-start(3);
  animation: fadeInSliderProject 0.25s $ease-smoth-in-out;
  grid-column: 1/5;

  grid-row:1/2;
  /*display: none;*/
  text-transform: uppercase;

  @keyframes fadeInSliderProject {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @include bp-desktop {
    @include grid-column-span(1);
    grid-row:1/1;
  }
}

.menuSliderLink {
  display: flex;

  .arrowIcon {
    margin-left: 0.5rem;

    .arrowIconWrapper {
      transform: translateX(-2.25rem);
    }
  }

  &:hover {
    .arrowIcon .arrowIconWrapper {
      transform: translateX(0.25rem);
    }
  }
}

.menuSliderStatus {
  @include grid-column-span(1);
  @include grid-column-start(4);
  display: none;
  text-transform: uppercase;

  @include bp-desktop {
    display: block;
  }
}

.menuSliderTitle {
  font-weight: 400;
}

.menuSliderNext {
  @include grid-column-span(1);
  @include grid-column-start(4);
  align-items: flex-start;
  display: flex;

  grid-row: 2/2;
  justify-content: flex-end;

  .arrowButton {
    display: flex;
    text-align: right;

    &:hover {
      .arrowIcon .arrowIconWrapper {
        transform: translateX(0.25rem);
      }
    }

    .arrowIcon {
      margin-left: 0.5rem;

      .arrowIconWrapper {
        transform: translateX(-2.25rem);
      }
    }
  }

  @include bp-desktop {
    @include grid-column-start(6);
    grid-row: 1/2;
  }
}

.arrowIcon {
  // margin: 0.1rem 0.5rem 0;
  // min-width: 2.25rem;
  // overflow: hidden;
  font-size: 1.6rem;
  margin-top: -0.1rem;

  @include bp-desktop-max {
    margin: 0 0 1rem;
  }

  .arrowIconWrapper {
    display: flex;
    transition: transform 0.3s $ease-expo-in;
    width: 6rem;

    svg {
      height: 1.4rem;
      margin-right: 0.5rem;
      width: 2rem;
    }
  }
}

.btnInactive {
  // color: $color-grey-1;
  color: #757575;
  pointer-events: none;
  user-select: none;

  svg {
    // fill: $color-grey-1;
    fill: #757575;
  }
}
