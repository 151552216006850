@import '../../styles/forwards/_all.scss';

.button {
  // overflow: hidden;
  position: relative;

  button, // action buttons (forms)
  a {
    transition: all 0.15s var(--ease-out);
    position: relative;

    span {
      overflow: hidden;

      svg {
        animation-duration: 5.5s;
        animation-delay: 0s;
        animation-timing-function: var(--ease-out);
      }
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    transform: scaleX(1);
    transform-origin: left;
    background-color: var(--white);
    opacity: 1;
    z-index: 0;
    will-change: transform;
  }

  &:hover {
    &::before {
      transition: transform 0.43s var(--ease-out);
      transform: scaleX(0);
      transform-origin: right;
    }
    span svg {
      animation-name: buttonarrowhoverX;
    }
  }

  &.BgTransparent {
    &::before {
      transform: scaleX(0);
      transform-origin: right;
    }

    &:hover {
      &::before {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
  }
}
