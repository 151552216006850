.play_btn {
  &:hover {
    button {
      &:before {
        transform: scale(1.1);
      }

      &:hover {
        span {
          color: var(--secondary);
        }
        &:before {
          transform: scale(1.2);
          background-color: var(--primary);
        }
      }
    }
  }

  button {
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: var(--white);
      z-index: 0;
      transition: transform 0.15s ease-out, background-color 0.15s ease-out;
    }

    span {
      z-index: 1;
      transition: color 0.15s ease-out;
    }

    @media (-ms-high-contrast: active), (forced-colors: active) {
      span {
        color: buttonText;
      }
    }
  }
}
