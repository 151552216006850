@import 'config';
// @use '_styles/forwards' as *;
// @use '_styles/forwards/colors' as *;

.wrapper {
  padding-top: 1.5rem;
  position: relative;
  text-align: center;

  @include bp-desktop-max {
    padding-top: 3rem;
  }

  .legend {
    margin-top: -3rem;
    position: relative;

    @include bp-desktop-max {
      margin-top: 3rem;
    }

    @include bp-desktop {
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .item {
      align-items: center;
      display: flex;
      margin-bottom: 1.5rem;
  
      .color {
        background-color: #fafafa;
        height: 1rem;
        width: 2rem;
      }
    
      .label {
        line-height: 1;
        margin-left: 1.6rem;
      }
    }
  }

  .card {
    display: flex;
    justify-content: center;
    left: 50%;
    max-width: 30rem;
    opacity: 0;
    pointer-events: none;    
    position: absolute;
    text-align: left;
    top: -10%;
    width: 100%;
    z-index: 100;
    
    .cardMask {
      align-items: center;
      background-color: #202020;
      display: flex;
      justify-content: center;
      overflow: hidden;
      transition: width 0.5s ease-out;
      width: 0%;
    }

    .cardContent {
      display: block;
      min-width: 30rem;      
      width: 100%;      

      .list {
        max-height: 45rem;
        overflow: auto;

        @include bp-desktop-max {
          max-height: none !important;
        }

        .item {
          border-top: solid 0.1rem #eff0f2;
          padding: 1.5rem 2.5rem;

          &:hover {
            background-color: #eff0f2;
          }
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1; 
        }
        
        &::-webkit-scrollbar-thumb {
          background: #333333; 
        }
        
        &::-webkit-scrollbar-thumb:hover {
          background: #333333; 
        }
      }
    }

    .cardImageMask {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 0rem;
      overflow: hidden;
      transition-delay: 0.5s;
      transition-duration: 0.5s;
      transition-property: height;      
      transition-timing-function: ease-out;
    }

    .cardImageWrapper {
      background-color: #ccc;
      height: 12rem;
      width: 100%;
    }

    .cardImage {
      float: left;
      height: 12rem;
      object-fit: cover;
      width: 100%;
    }
    
    .city {
      font-size: 1.2rem;
    }

    .title {
      /* @include font-headline-4; */
      font: normal 700 2.6rem/1 "Helvetica Neue", sans-serif;
      letter-spacing: -0.072rem;
      margin-bottom: 0;
      margin-top: 1rem;
      max-width: 19rem;
      text-indent: 0;
      text-transform: uppercase;      
    }

    .cta {      
      float: right;
      text-decoration: underline;

      span {
        font-size: 1.2rem;
        font-weight: normal;
        text-decoration: underline;
        text-transform: none;

        + span {
          display: none;
        }
      }
    }

    &.active {
      opacity: 1;
      pointer-events: auto;
      transition: opacity 0.2s ease-out, transform 0.2s ease-out;

      &.hidding {
        opacity: 0;
        pointer-events: none;
        transform: scale(0.8);
      }
      
      .cardMask {
        width: 100%;
      }

      .cardImageMask {
        height: 12rem;
      }
    }

  }

  .cardWrapper {
    .closeCard {
      display: none;
    }

    @include bp-desktop-max {
      align-items: center;
      bottom: 0;
      display: none;      
      flex-direction: column;
      left: 0;
      padding: 10rem 2rem 2rem 2rem;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 20;

      &.active {
        display: flex;
      }
      
      &:before {
        background-color: rgba(0, 0, 0, 0.5);
        bottom: -30%;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }

      .card {
        display: none;
        max-width: 100%;
        opacity: 1;
        pointer-events: auto;
        position: static;

        &.visible {
          display: block;
        }

        .cardMask {
          width: 100%;
        }

        .cardImageMask {
          height: 100%;
        }
      }

      .cardScroll {
        overflow: auto;
        position: relative;
        width: 100%;
        z-index: 1;
      }

      .closeCard {
        display: block;
        max-width: 4rem;
        position: absolute;
        right: 2rem;
        top: 5rem;
        z-index: 2;
        
        circle {
          opacity: 1 !important;          
        }

        circle, path {
          filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2));
        }
      }
    }
  }

  .clickMobile {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  svg {
    display: inline-block;
    height: auto;
    max-width: 85rem;
    width: 100%;

    * { transform-box: fill-box; }
  }

  circle {
    opacity: 0;
    // transition: all 0.3s ease-out;
    // transition-delay: 2.1s;
    // transform: scale(0.5);
    transform-origin: center center;
  }

  [data-main=true] circle {
    transform: scale(0.5);
    transition: all 0.3s ease-out;
    transition-delay: 2.1s;
  }

  path { transition: all 0.5s ease; }

  g[class] {
    cursor: pointer;
    opacity: 0;
    transform-origin: center center;
  }

  &.animate g[class], &.animate path[class] {
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-name: stateAnimation;
  }

  &.animate {
    circle {      
      opacity: 1;
      transform: scale(1);
    }
  }

  &.animate2 {
    circle {
      transform: none !important;
      transition: none !important;
    }
  }

  @keyframes stateAnimation {
    from {
      opacity: 0;                 
      transform: scale(0.8);
    }
    to { 
      opacity: 1;
      transform: scale(1);
    }
  }

  /*
  .ca { animation-delay: 0.05s }
  .or { animation-delay: 0.10s }
  .wa { animation-delay: 0.15s }
  .id { animation-delay: 0.20s }
  .hi { animation-delay: 0.25s }
  .ak { animation-delay: 0.30s }
  .az { animation-delay: 0.40s }
  .mt { animation-delay: 0.45s }
  .nv { animation-delay: 0.50s }
  .ut { animation-delay: 0.55s }
  .co { animation-delay: 0.60s }
  .nm { animation-delay: 0.65s }
  .wy { animation-delay: 0.70s }
  .nd { animation-delay: 0.75s }
  .sd { animation-delay: 0.80s }
  .ne { animation-delay: 0.85s }
  .ks { animation-delay: 0.90s }
  .ok { animation-delay: 0.95s }
  .tx { animation-delay: 1.00s }
  .mn { animation-delay: 1.05s }
  .ia { animation-delay: 1.10s }
  .mo { animation-delay: 1.15s }
  .ar { animation-delay: 1.20s }
  .la { animation-delay: 1.25s }
  .wi { animation-delay: 1.30s }
  .il { animation-delay: 1.35s }
  .ky { animation-delay: 1.40s }
  .tn { animation-delay: 1.45s }
  .ms { animation-delay: 1.50s }
  .mi { animation-delay: 1.55s }
  .in { animation-delay: 1.60s }
  .al { animation-delay: 1.65s }
  .fl { animation-delay: 1.70s }
  .oh { animation-delay: 1.75s }
  .va { animation-delay: 1.80s }
  .nc { animation-delay: 1.85s }
  .sc { animation-delay: 1.90s }
  .ga { animation-delay: 1.95s }
  .ny { animation-delay: 2.00s }
  .pa { animation-delay: 2.05s }
  .md { animation-delay: 2.10s }
  .wv { animation-delay: 2.15s }
  .de { animation-delay: 2.20s }
  .nj { animation-delay: 2.25s }
  .me { animation-delay: 2.30s }
  .vt { animation-delay: 2.35s }
  .nh { animation-delay: 2.40s }
  .ma { animation-delay: 2.45s }
  .ct { animation-delay: 2.50s }
  .ri { animation-delay: 2.55s }*/


  .ca { animation-delay: 0.04s }
  .or { animation-delay: 0.08s }
  .wa { animation-delay: 0.12s }
  .id { animation-delay: 0.16s }
  .hi { animation-delay: 0.20s }
  .ak { animation-delay: 0.24s }
  .az { animation-delay: 0.28s }
  .mt { animation-delay: 0.32s }
  .nv { animation-delay: 0.36s }
  .ut { animation-delay: 0.40s }
  .co { animation-delay: 0.44s }
  .nm { animation-delay: 0.48s }
  .wy { animation-delay: 0.52s }
  .nd { animation-delay: 0.56s }
  .sd { animation-delay: 0.60s }  
  .ne { animation-delay: 0.64s }
  .ks { animation-delay: 0.68s }
  .ok { animation-delay: 0.72s }
  .tx { animation-delay: 0.80s }
  .mn { animation-delay: 0.84s }
  .ia { animation-delay: 0.88s }
  .mo { animation-delay: 0.92s }
  .ar { animation-delay: 0.96s }
  .la { animation-delay: 1.00s }
  .wi { animation-delay: 1.04s }
  .il { animation-delay: 1.08s }
  .ky { animation-delay: 1.12s }
  .tn { animation-delay: 1.16s }
  .ms { animation-delay: 1.20s }
  .mi { animation-delay: 1.24s }
  .in { animation-delay: 1.28s }
  .al { animation-delay: 1.32s }
  .fl { animation-delay: 1.36s }
  .oh { animation-delay: 1.40s }
  .va { animation-delay: 1.44s }
  .nc { animation-delay: 1.48s }
  .sc { animation-delay: 1.52s }
  .ga { animation-delay: 1.56s }
  .ny { animation-delay: 1.60s }
  .pa { animation-delay: 1.64s }
  .md { animation-delay: 1.68s }
  .wv { animation-delay: 1.72s }
  .de { animation-delay: 1.76s }
  .nj { animation-delay: 1.80s }
  .me { animation-delay: 1.84s }
  .vt { animation-delay: 1.88s }
  .nh { animation-delay: 1.92s }
  .ma { animation-delay: 1.96s }
  .ct { animation-delay: 2.00s }
  .ri { animation-delay: 2.04s }

  g[class*="marker-"] {
    pointer-events: none;
  }
}
