.imageContainer {
  position: relative;

  &,
  .imageRef {
    z-index: 1;
    transition: transform 0.15s ease;
    transform-style: preserve-3d;
    will-change: transform;
  }
}

.imageContainer {
  &:hover .imageRef {
    transform: translateZ(30px);
  }
}
