.item {
  padding-left: 12px;

  .plusMinusIcon {
    position: relative;
    transition: transform 0.5s var(--ease-out);
    height: 30px;
    min-width: 30px;
    will-change: transform;

    @media screen and (min-width: 768px) {
      height: 35px;
      min-width: 35px;
    }

    @media screen and (min-width: 1024px) {
      height: 47px;
      min-width: 47px;
    }

    &::before,
    &::after {
      position: absolute;
      content: '';
      height: 1px;
      width: 9px;
      left: 10px;
      top: 13px;
      background-color: var(--white);
      transform-origin: center;
      will-change: transform;

      @media screen and (min-width: 768px) {
        height: 2px;
        width: 15px;
        top: 14px;
        left: 8px;
      }

      @media screen and (min-width: 1024px) {
        top: 21px;
        left: 14px;
      }
    }

    &::after {
      transform: rotate(90deg) scaleY(1);
      transition: transform 0.5s var(--ease-out), background-color var(--ease-out);
      background-color: var(--white);
    }
  }

  .paragraph {
    margin-left: 0.3rem;
    color: var(--primary-light);
    transition: max-height 0.5s var(--ease-out), opacity 0.5s var(--ease-out),
      visibility 0.5s var(--ease-out);
    will-change: max-height;
  }

  &:hover {
    .plusMinusIcon {
      transform: scale(1.1);
    }
  }
}

.isOpen {
  .plusMinusIcon {
    &::before {
      background-color: var(--secondary);
    }

    &::after {
      background-color: var(--secondary);
      transform: rotate(90deg) scaleX(0);
    }
  }
}
