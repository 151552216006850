.modalGradient {
  // mobile view
  @apply bottom-16 left-16 right-16;
  @apply fixed mx-auto w-auto h-200;

  // hide gradient on desktop
  @apply md:hidden;

  // allows scroll on top of it
  @apply pointer-events-none;
  background: linear-gradient(#ffffff00, var(--secondary));
}

.modal {
  height: 96vh;
  @apply flex flex-col md:flex-row;
  @apply bg-secondary relative z-50;
  @apply p-16 md:p-0 md:w-1050 md:h-auto md:min-h-40;
  @apply overflow-x-hidden overflow-y-scroll md:overflow-x-hidden md:overflow-y-hidden;

  @media screen and (max-width: 768px) {
    height: 100%;
  }

  @media screen and (min-width: 768px) {
    p {
      margin-bottom: 1.5rem !important;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}

.modalVideo {
  @apply flex items-center justify-center;
  @apply bg-secondary relative z-50;
  @apply h-full p-16 md:p-0 md:w-3/4 md:h-auto md:min-h-40;
  @apply overflow-x-hidden overflow-y-scroll md:overflow-x-hidden md:overflow-y-hidden;

  @media screen and (max-width: 768px) {
    height: 100%;
  }

  iframe {
    height: 100%;
    
    @media screen and (min-width: 768px) {
      aspect-ratio: 16/9;
    }
  }
}

.button {
  &:before {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid var(--primary);
    transition: transform 0.15s ease-out;
    z-index: -1;
    background-color: var(--secondary);
  }

  &:hover,
  &:focus {
    &:before {
      transform: scale(1.2);
    }
  }
}
