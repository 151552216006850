@import '../../styles/forwards/css-animations.scss';

.titleFirstLine {
  @media screen and (min-width: 768px) {
    transform: translate(-22%);
  }

  span {
    display: block;
    transform: translateY(100%);
    will-change: transform;
    animation: bottom-100-top-0 1s cubic-bezier(0, 0, 0.2, 1) forwards;
  }
}

.titleSecondLine {
  @media screen and (min-width: 768px) {
    transform: translate(10%);
  }

  span {
    display: block;
    transform: translateY(100%);
    animation-delay: 0.1s;
    will-change: transform;
    animation: bottom-100-top-0 1s cubic-bezier(0, 0, 0.2, 1) forwards;
  }
}

.titleFirstLine,
.titleSecondLine {
  svg path {
    @apply fill-current;

    @media (-ms-high-contrast: active), (forced-colors: active) {
      svg path {
        fill: buttonText;
      }
    }
  }
}

.titleFirstLineExit {
  will-change: transform;
  animation: bottom-0-top-100 1s cubic-bezier(0, 0, 0.2, 1) forwards !important;
}

.titleSecondLineExit {
  will-change: transform;
  animation: bottom-0-top-100 1s cubic-bezier(0, 0, 0.2, 1) forwards !important;
  animation-delay: 0.15s;
}
