// don't overwrite this with tailwind class 'text-center'
// it doesn't work, tried & tested
.nextProject {
  h2,
  h3 {
    * {
      @media screen and (max-width: 768px) {
        text-align: center !important;
      }
    }
  }
  opacity: 0.7;
  transition: opacity 0.2s ease-in;

  &:hover {
    opacity: 1;
  }
}

.arrowButton {
  width: 6rem;
  height: 6rem;

  @media screen and (min-width: 768px) {
    width: 10rem;
    height: 10rem;
  }
}
