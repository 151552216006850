@keyframes buttonarrowhoverX {
  0% {
    transform: translateX(0px);
  }
  5% {
    transform: translateX(25px);
  }
  6% {
    transform: translateY(-100px);
  }
  7% {
    transform: translateX(-25px);
  }
  8% {
    transform: translateY(0px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes bottom-100-top-0 {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes bottom-0-top-100 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
