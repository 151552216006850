// Tailwind imports
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #cda873;
  --primary-light: #757676;
  --secondary: #202020;
  --background: #292a2a;
  --image: #757676;
  // add more color variables here
  --white: #fff;

  --ease-out: cubic-bezier(0, 0, 0.2, 1);
  --ease-in: cubic-bezier(0, 0, 1, 0.2);
  --ease-in-out: cubic-bezier(0.8, 0, 0.2, 1);
}

@layer utilities {
  // generic hero padding top utility
  .hero-pt {
    @apply pt-120 md:pt-220;
  }
  .button-underline {
    display: inline-block;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--secondary);
      transform: scaleX(0);
      transition: transform 0.25s ease-out;
      transform-origin: right;
      opacity: 1;
    }
    &:hover {
      &:after {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
  }
  // remove scrollbar on mobile
  .scrollbar-hide {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  // High Contrast Utility
  .h-contrast {
    @media (-ms-high-contrast: active), (forced-colors: active) {
      svg path {
        fill: buttonText;
      }
    }
  }
  // Force Fonts
  .font-robotoMedium * {
    font-family: 'Roboto Medium';
  }

  .font-bebasNeue * {
    font-family: 'Bebas Neue Regular';
    letter-spacing: 0.042em;
    color: var(--primary);
  }

  // Scale down h1 on smaller screens (425, 375, 320)
  // scale down more 10 chars
  .h1-scale-down-A {
    @apply sm320:text-3xl-A  sm375:text-3xl-G sm425:text-4xl-A;
    // @apply sm425:bg-red-900 sm375:bg-red-600 sm320:bg-red-300;
  }

  // scale down more 8 chars
  .h1-scale-down-B {
    @apply sm320:text-3xl-G sm375:text-4xl-C;
    // @apply sm425:bg-pink-900 sm375:bg-pink-600 sm320:bg-pink-300;
  }

  // Scale down h2 on smaller screens (425, 375, 320)
  .h2-scale-down-A {
    @apply sm320:text-3xl-A  sm375:text-3xl-G sm425:text-4xl-A;
    // @apply sm425:bg-blue-900 sm375:bg-blue-600 sm320:bg-blue-300;
  }

  .h2-scale-down-B {
    @apply sm320:text-3xl-G sm375:text-4xl-A;
    // @apply sm425:bg-yellow-900 sm375:bg-yellow-600 sm320:bg-yellow-300;
  }
}
