@import 'config';

// @use '_styles/forwards' as *;

.locationMapInteractive {
  margin-bottom: 4rem;
  padding: 7rem $page-padding-x 7rem;

  @include bp-desktop {
    padding: 8rem 0;
  }
}

.gridWrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  @include bp-desktop {
    @include grid;
    align-items: center;
  }
}

.col1 {
  @include grid-column-span(4);
  margin-bottom: 4rem;

  @include bp-desktop {
    margin-bottom: 0;
  }

  .gridWrapperLayout3and3 & {
    @include grid-column-span(3);
  }
}

.col2 {
  @include grid-column-span(2);

  @include bp-desktop-max {
    padding-right: 4.5rem;
  }

  .gridWrapperLayout3and3 & {
    @include grid-column-span(3);
  }
}

.row2 {
  position: relative;
}

.title {
  // @include font-headline-2;
  line-height: 0.833;
  margin-bottom: 3.5rem;
  text-align: center;

  @include bp-desktop {
    line-height: 0.93;
    margin-bottom: 4.5rem;
  }
}

.text {
  border-top: solid 1px #cccccc;
  line-height: 1.5;
  margin-top: 1rem;
  margin-top: 2rem;
  padding: 2rem 0;
  text-align: left;

  @include bp-desktop {
    border: 0;
    left: 50%;
    margin: 0;
    margin-left: 49rem;    
    max-width: 18rem;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.image {
  @include bp-desktop {
    padding-right: 8.5rem;
  }

  img {
    image-rendering: optimizeQuality;
  }
}

.locationMap {
  margin-top: 0;
  padding-left: 4rem;
  padding-right: 4rem;

  @include bp-desktop-max {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.locationNav {
  margin-top: 2.5rem;  
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 1rem;
  white-space: nowrap;
    
  .locationNavItem {
    border: solid 1px rgba($color: $color-black, $alpha: 0.16);
    cursor: pointer;
    display: inline-block;
    margin: 0.5rem;
    transition: all 0.5s ease-out;
    width: auto;

    &:first-child {
      margin-left: 0;
    }

    &.actived {
      background-color: rgb(164,172,189);
      border: solid 1px rgba($color: rgb(164,172,189), $alpha: 1);
      color: $color-white;

      svg {
        circle:first-child {          
          stroke: $color-white;
        }

        circle:last-child {
          fill: $color-white;          
        }
      }
    }


    .locationNavLabel {      
      display: block;
      float: left;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1;
      margin-right: 1.1rem;
      padding-top: 0.4rem;
      text-transform: uppercase;
      white-space: normal;
      width: auto;
    }

    .locationNavRadio {
      float: left;
      width: 1.6rem;
    }

    .locationNavWrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 1rem;

      @include bp-desktop-max {
        padding: 0.9rem 1.7rem;
      }
    }
  }
}