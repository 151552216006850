.textArea {
  span *,
  p *,
  p > span *,
  li * {
    @apply -tracking-3;
    color: var(--primary-light) !important;
    font-family: 'Roboto Medium' !important;
    font-size: 1.6rem !important;
    line-height: 1.35 !important;
    font-style: normal;
    text-decoration: none !important;
  }

  ul {
    @apply my-30 mx-0;

    li {
      @apply list-none list-inside flex pl-10 align-middle mb-10;

      &:before {
        @apply uppercase font-normal text-3xl-D pr-10 left-0 align-middle;
        content: '·';
      }
    }
  }

  strong {
    @apply uppercase font-normal;
  }

  @media screen and (max-width: 768px) {
    @apply pr-20;
  }
}
