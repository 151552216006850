.contact_bio,
.contact_direction {
  display: inline-block;
  width: fit-content;
  position: relative;

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: var(--white);
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.25s var(--ease-out);
    transition-delay: 0.3s;
    opacity: 0.85;
    line-height: 0;
    will-change: transform;
  }

  &:before {
    transform: scaleX(1);
    transform-origin: right;
    transition-delay: 0s;
  }

  &:hover {
    &:after {
      transform: scaleX(1);
      transform-origin: left;
    }
    &:before {
      transform: scaleX(0);
      transform-origin: right;
      transition-delay: 0s;
    }
  }
}

.contact_direction {
  line-height: 1.15;
}

/*.contact_profile {
  min-width: 10.7rem;
  max-height: 14.4rem;
  overflow: hidden;
}*/
