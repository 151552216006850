// Default - Mobile View
.benefitGrid {
  @apply grid grid-cols-8 md:grid-cols-9;
  > div {
    @apply border-primary-light;

    &:last-child {
      @apply border-b-0;
    }
  }
}

// Desktop View 3x3 Tic-tac-toe borders
@media (min-width: theme('screens.md')) {
  .threeItems {
    @apply grid grid-cols-8 md:grid-cols-9;
    > div {
      @apply border-primary-light;

      &:nth-child(2) {
        @apply border-l border-r;
      }
    }
  }

  .fourItems {
    @apply grid grid-cols-8 md:grid-cols-9;
    > div {
      @apply border-primary-light;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        @apply border-b;
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(4) {
        @apply border-r;
      }
    }
  }

  .fiveOrSixItems {
    @apply grid grid-cols-8 md:grid-cols-9;
    > div {
      @apply border-primary-light;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        @apply border-b;
      }

      &:nth-child(2),
      &:nth-child(5) {
        @apply border-r border-l;
      }
    }
  }

  .sevenOrMoreItems {
    @apply grid grid-cols-8 md:grid-cols-9;
    > div {
      @apply border-primary-light;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(5) {
        @apply border-r border-b;
      }

      &:nth-child(3),
      &:nth-child(6) {
        @apply border-b;
      }

      &:nth-child(7),
      &:nth-child(8) {
        @apply border-r;
      }
    }
  }

  .tenOrMoreItems {
    @apply grid grid-cols-8 md:grid-cols-9;
    > div {
      @apply border-primary-light;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(7),
      &:nth-child(8) {
        @apply border-r border-b;
      }

      &:nth-child(3),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9) {
        @apply border-b;
      }

      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(10),
      &:nth-child(11) {
        @apply border-r;
      }
    }
  }
}
