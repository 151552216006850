.megamenuBackground {
  @apply bg-cover bg-bottom bg-no-repeat bg-primary;
  background-image: url('/images/megamenu-bg.webp');
}

.NavItemsWrapper {
  @apply flex flex-col items-start justify-between my-auto md:items-end;
}

.textPrimary {
  @apply text-2xl-A text-left md:text-right -tracking-3;

  &:hover {
    @apply duration-300 opacity-100;
  }
}

.textSecondary {
  @apply text-xs-B text-right -tracking-3;
}

@media screen and (min-height: 500px) {
  .textPrimary {
    @apply text-2xl-G;
  }
}

@media screen and (min-height: 800px) {
  .NavItemsWrapper {
    @apply md:mt-150;
  }
  .textPrimary {
    @apply text-3xl-C md:text-4xl-F;
  }
  .textSecondary {
    @apply text-xs-B md:text-base;
  }
}

.active {
  // TODO: add styles here
}
