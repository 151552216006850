.card {
  @apply border-b border-primary;
  transition: background-color 0.15s var(--ease-out);

  .container {
    transition: transform 0.3s var(--ease-out);
  }

  .copy {
    transition: opacity 0.3s var(--ease-out);
    @apply md:opacity-50 opacity-100;

    .subheading > div:first-child {
      transition: width 0.3s var(--ease-out);
    }
  }

  // .cardImage {
  //   pointer-events: none;
  //   opacity: 0;
  //   transform: rotate(6deg) scale(1.05);
  //   transition: transform 0.2s ease-in 0.5s, opacity 0.2s ease-in;
  // }

  &:hover {
    background-color: var(--background);

    @media screen and (min-width: 764px) {
      .container {
        transform: translateX(5rem);
      }
    }

    .subheading > div:first-child {
      width: 7.5rem;
    }

    // .cardImage {
    //   opacity: 1;
    //   transform: rotate(3deg) scale(1);
    //   transition: opacity 0.3s ease-in, transform 0.2s ease-in;
    // }

    .copy {
      @apply opacity-100;
    }
  }

  // mobile view
  @media screen and (min-width: 764px) {
    :hover {
      // .cardImage {
      //   display: block;
      // }
    }
  }
}

// .cardImage {
//   @apply absolute -mt-60 transform w-140 md:w-350;
// }
