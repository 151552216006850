.item:not(div) {
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    z-index: -1;
    transition: background-color 0.15s var(--ease-out);
  }

  .subheading > div:first-child {
    transition: width 0.3s var(--ease-out);
  }

  &:hover {
    &:before {
      background-color: var(--background);
    }

    .subheading {
      > div:first-child {
        width: 7.5rem;
      }
    }
  }
}
