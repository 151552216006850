// @use '_styles/forwards' as *;


// Colors

$color-black: #333333;
$color-black-text: #333333;
$color-grey-5: #333333;
$color-white: #ffffff;


// Easings

$ease-linear: cubic-bezier(0, 0, 1, 1);
$ease-smoth-in-out: cubic-bezier(0.5, 0, 0.5, 1);
$ease-smoth-in-out2: cubic-bezier(0.36, 0, 0, 1);
$ease-expo-in: cubic-bezier(0, 0.1, 0.5, 1);
$ease-expo-in2: cubic-bezier(0.02, 0.98, 0.72, 1);
$ease-expo-out: cubic-bezier(0.5, 0, 0.99, 0.9);
$ease-expo-in-out: cubic-bezier(0, 0.74, 1, 0.25);
$ease-expo-in-out2: cubic-bezier(0.76, 0, 0.25, 1);
$ease-ease-out: cubic-bezier(0, 0, 0.2, 1);


// Spacing
$page-padding-x: 2rem;
$page-padding-x-large: 4rem;

$default-grid-gap-y: 2rem;
$default-grid-gap-y-large: 4rem;

$section-padding-y: 8rem;
$section-padding-y-large: 12rem;

$project-builder-padding-x: 12rem;
$project-builder-padding-x-large: 20rem;

// Breakpoints

$bp-xs: 415px;
$bp-xs-max: calc(#{$bp-xs} - 1px);

$bp-s: 650px;
$bp-s-max: calc(#{$bp-s} - 1px);

$bp-ms: 768px;
$bp-ms-max: calc(#{$bp-ms} - 1px);

$bp-m: 1024px;
$bp-m-max: calc(#{$bp-m} - 1px);

$bp-ml: 1440px;
$bp-ml-max: calc(#{$bp-ml} - 1px);

$bp-ls: 1680px;
$bp-ls-max: calc(#{$bp-ls} - 1px);

$grid-gutter-width: $default-grid-gap-y;
$grid-gutter-width-large: $default-grid-gap-y-large;
$grid-column-count: 4;
$grid-column-count-large: 6;

$breakpoints: (
  xs: $bp-xs,
  s: $bp-s,
  ms: $bp-ms,
  m: $bp-m,
  ml: $bp-ml,
  ls: $bp-ls,
) !default;

$breakpoints-max: (
  xs: $bp-xs-max,
  s: $bp-s-max,
  ms: $bp-ms-max,
  m: $bp-m-max,
  ml: $bp-ml-max,
  ls: $bp-ls-max,
) !default;

@mixin bp($value) {
  @media only screen and (min-width: $value) {
    @content;
  }
}

@mixin bp-max($value) {
  @media only screen and (max-width: $value) {
    @content;
  }
}

@mixin bp-desktop {
  @include bp(map-get($breakpoints, 'ms')) {
    @content;
  }
}

@mixin bp-desktop-max {
  @include bp-max(map-get($breakpoints-max, 'ms')) {
    @content;
  }
}

@mixin grid-column-span($column-count) {
  grid-column: span $column-count / span $column-count;
}

@mixin grid-column-start($column-count-start) {
  grid-column-start: $column-count-start;
}

@mixin grid {
  display: grid;
  grid-gap: $grid-gutter-width;
  grid-template-columns: repeat(#{$grid-column-count}, 1fr);
  padding: 0 $page-padding-x;

  @include bp-desktop {
    grid-gap: $grid-gutter-width-large;
    grid-template-columns: repeat(#{$grid-column-count-large}, 1fr);
    padding: 0 $page-padding-x-large;
  }

  > * {
    @include grid-column-span($grid-column-count);

    @include bp-desktop {
      @include grid-column-span($grid-column-count-large);
    }
  }
}

@keyframes tilt {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}