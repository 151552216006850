.card {
  position: relative;
  z-index: 0;

  .container {
    transition: transform 0.3s var(--ease-out);
  }

  .subheading > div:first-child {
    transition: width 0.3s var(--ease-out);
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    z-index: -1;
    transition: background-color 0.15s ease-out;
  }

  &:hover {
    @media screen and (min-width: 764px) {
      .container {
        transform: translateX(3rem);
      }
    }

    .subheading > div:first-child {
      width: 7.5rem;
    }

    &:before {
      background-color: var(--secondary);
    }
  }
}
