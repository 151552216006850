.ImageContainer {
  .slider_drag {
    transition: opacity 0.15s var(--ease-out), transform 0.15s var(--ease-out);
    will-change: transform;

    @media screen and (min-width: 768px) {
      opacity: 0;
    }
  }

  &:hover {
    .slider_drag {
      opacity: 1;
      transform: scale(1.1) translate(calc(-50% + 1rem / 2), calc(-50% + 1rem / 2));
    }
  }
}

.buttonSlider {
  height: 40px;
  width: 40px;

  @media screen and (min-width: 768px) {
    height: 30px;
    width: 30px;
  }

  @media screen and (min-width: 1024px) {
    height: 40px;
    width: 40px;
  }
}

.image {
  img {
    background-color: var(--image);
  }
}
