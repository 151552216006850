.skipToContentLink {
  @apply absolute bg-secondary text-primary text-xl h-auto px-20 py-15 border-0 focus:outline-none focus-visible:outline-black left-30;
  outline-offset: 0 !important;
  transform: translateY(-100%);
  transition: transform 0.3s;
  z-index: 60;

  &:focus-visible,
  &:focus {
    transform: translateY(-5%);
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}
