.hamburger {
  will-change: transform;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--secondary);
    border: 2px solid var(--primary-light);
    z-index: -1;
    will-change: transform;
    transition: transform 0.15s ease-in, background-color 0.15s ease-in;

    @media (-ms-high-contrast: active), (forced-colors: active) {
      background-color: ButtonText;
    }
  }

  svg {
    will-change: transform;
    transition: transform 0.15s ease-in;

    path {
      @media (-ms-high-contrast: active), (forced-colors: active) {
        fill: ButtonFace;
      }
    }
  }

  &:hover {
    &:before {
      transform: scale(1.15);
    }
    svg {
      //transform: scale(0.95);
    }
  }

  &.active {
    &:before {
      border: 2px solid var(--white);
      content: '';
      background-color: var(--primary);
      z-index: -1;

      @media (-ms-high-contrast: active), (forced-colors: active) {
        background-color: ButtonText;
      }
    }

    svg {
      path {
        fill: var(--white);
        @media (-ms-high-contrast: active), (forced-colors: active) {
          fill: ButtonFace;
        }
      }
    }
  }
}
