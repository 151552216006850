.fullTestimonial {
    @apply bg-secondary border border-primary;
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(100% * 2 + 2.4rem);
    width: 100%;
    z-index: 1;
    padding: 0 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    display: none;

    @media screen and (max-width: 768px) {
        height: calc(100% * 2 + 1.6rem);
    }

    &[index='0'] {
        @media screen and (max-width: 768px) {
            top: 0;
            bottom: auto;
        }
    }

    &[index='1'] {
        height: 100%;

        @media screen and (max-width: 768px) {
            height: calc(100% * 2 + 1.6rem);
            top: 0;
            bottom: auto;
        }
    }

    &[index='2'] {
        bottom: auto;
        top: 0;
    }

    &[index='3'] {
        @media screen and (max-width: 768px) {
            height: calc(100% * 2 + 1.6rem);
            top: 0;
            bottom: auto;
        }
    }

    &[index='4'] {
    }

    &[index='5'] {
        bottom: auto;
        top: 0;

        @media screen and (max-width: 768px) {
            top: auto;
            bottom: 0;
        }
    }

    &[index='6'] {
    }
}

.isSmallTestimonial {    
    &[index='0'], &[index='1'], &[index='2'], &[index='3'] {
        top: auto;
        bottom: 0;        
    }
}