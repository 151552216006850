.heading {
  * {
    @media screen and (max-width: 768px) {
      padding-left: 0 !important;
    }
  }

  > div > div {
    display: inline-flex !important; // prevents character drops at random device widths
  }
}
