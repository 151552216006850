.slider_card {
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 40px);
    height: calc(100% + 40px);
    transform: translate(-50%, -50%);
    background-color: transparent;
    z-index: -1;
    transition: background-color 0.15s ease-out;

    @media screen and (max-width: 768px) {
      width: calc(100% + 20px);
      height: calc(100% + 20px);
    }
  }

  .slider_drag {
    transition: opacity 0.15s var(--ease-out), transform 0.15s var(--ease-out);
    will-change: transform;

    @media screen and (min-width: 768px) {
      opacity: 0;
    }
  }

  &:hover {
    &:before {
      background-color: var(--secondary);
    }

    .slider_drag {
      opacity: 1;
      transform: scale(1.1) translate(calc(-50% + 1rem / 2), calc(-50% + 1rem / 2));
    }
  }
}

.slider_card_drag {
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 40px);
    height: calc(100% + 40px);
    transform: translate(-50%, -50%);
    background-color: transparent;
    z-index: -1;
    transition: background-color 0.15s ease-out;

    @media screen and (max-width: 768px) {
      width: calc(100% + 20px);
      height: calc(100% + 20px);
    }
  }

  .slider_drag {
    transition: opacity 0.15s var(--ease-out), transform 0.15s var(--ease-out);
    will-change: transform;

    @media screen and (min-width: 768px) {
      opacity: 0;
    }
  }

  &:hover {
    /*&:before {
      background-color: var(--background);
    }*/

    .slider_drag {
      opacity: 1;
      transform: scale(1.1) translate(calc(-50% + 1rem / 2), calc(-50% + 1rem / 2));
    }
  }
}
